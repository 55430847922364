<template>
  <CModal
    title="Are you sure?"
    color="danger"
    :show.sync="showModal"
    @update:show="close(...arguments)"
    :fade="false"
  >
    This will permanently delete {{this.entity}} {{this.name}}.
  </CModal>
</template>

<script>
export default {
  name: "DeleteModal",
  props: ["show", "entity", "name", "nid"],
  methods: {
    close(status, evt, accept) {
      if (accept) {
        this.$emit('delete', {nid: this.nid, entity: this.entity})
      }else{
        this.$emit('cancel-delete', true)
      }
    }
  },
  computed: {
    showModal: {
      get: function() {
        return this.show
      },
      set: function (){
      }
    }
  }
}
</script>

<style scoped>

</style>