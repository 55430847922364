<template>
  <div>
    <CModal
        title="New Sales Agent"
        size="xl"
        :show.sync="showModal"
        @update:show="closeAddModal"
    >
      <CAlert
          v-if="error"
          color="danger"
          closeButton
          @update:show="removeError">
        {{error}}
      </CAlert>
      <SalesAgentForm
          v-bind:sales-agent-in="salesAgent"
          v-bind:sales-office="salesOffice"
          v-on:update-item="updateItem"
          v-on:error="catchError"
      />
    </CModal>
  </div>
</template>

<script>
import gql from "graphql-tag";
import SalesAgentForm from "@/apps/partners/components/SalesAgentForm";

const createSalesAgent = gql`
  mutation createSalesAgent($salesAgent: SalesAgentInput){
    createSalesAgent(salesAgent: $salesAgent) {
      nid
      firstName
      lastName
      activePapp
      msActivePapp
      imActivePapp
      salesOffice{
        nid
        name
      }
    }
  }
`

export default {
  name: "SalesAgentAdd",
  props: ["salesOffice"],
  components: {
    SalesAgentForm
  },
  data(){
    return {
      salesAgentSave: {},
      error: null
    }
  },
  computed: {
    showModal: {
      get: function() {
        return this.$store.state.addAgentModalShow
      },
      set: function (){
      }
    },
    salesAgent: {
      get: function() {
        return {
          activePapp: false,
          msActivePapp: false,
          salesOffice: this.salesOffice
        }
      },
      set: function (){
      }
    }
  },
  methods: {
    updateItem(data){
      this.salesAgentSave = data;
    },
    closeAddModal(status, evt, accept){
      if (accept) {
        this.$apollo.mutate({
          mutation: createSalesAgent,
          variables: {
            salesAgent: this.salesAgentSave
          },
        })
        .then(res => {
          this.$emit('add-item', res.data.createSalesAgent);
          this.salesAgent = {};
          this.salesAgentSave = {};
          this.$store.commit('set', ['addAgentModalShow', false]);
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
        })
       }else{
        this.salesAgent = {};
        this.salesAgentSave = {};
        this.$store.commit('set', ['addAgentModalShow', false]);
       }
    },
    catchError(event){
      this.error = event
    },
    removeError(hide){
      if(!hide){
        this.error = null
      }
    }
  }
}
</script>
