<template>
  <div>
    <CSpinner v-if="loading" color="info"/>
    <CRow class="add-button-row">
      <CButton
          variant="outline"
          color="info"
          @click="openAddModal">
        Add Sales Agent
      </CButton>
    </CRow>
    <SalesAgentAdd
        v-bind:sales-office="salesOffice"
        v-on:add-item="addItemLocal"
    />
    <CCardBody>
      <CDataTable
        :items="items"
        :fields="fields"
        table-filter
        items-per-page-select
        :items-per-page="itemsPerPage"
        v-on:update:sorter-value="updateOrder"
        v-on:update:table-filter-value="updateFilter"
        v-on:pagination-change="updatePagination"
        hover
        sorter
        :pagination="false"
      >
        <template #edit_item="{item}">
          <td class="py-2">
            <TableButtonGroup
                v-on:view="viewItem(item)"
                v-on:edit="editItem(item)"
                v-on:delete-prompt="toggleDeleteItemModal(item)"
                :disable-edits="!allowEdit"
            />
            <DeleteModal
                entity="sales-agent"
                :name="deleteItemName"
                :nid="deleteItemID"
                :show.sync="showDeleteItemModal"
                v-on:delete="deleteItem(item)"
                v-on:cancel-delete="toggleDeleteItemModal(item)"
            />
          </td>
        </template>
        <template #mandatoryMember="{item}">
          <td>
            <CIcon v-if="item.mandatoryMember" name="cil-check-circle"/>
            <CIcon v-if="!item.mandatoryMember" name="cil-x-circle"/>
          </td>
        </template>
        <template #activePapp="{item}">
          <td>
            <CIcon v-if="item.activePapp" name="cil-check-circle"/>
            <CIcon v-if="!item.activePapp" name="cil-x-circle"/>
          </td>
        </template>
      </CDataTable>
      <CPagination
          :activePage.sync="activePage"
          :pages="pages"
      />
    </CCardBody>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import DeleteModal from "@/components/common/DeleteModal";
import TableButtonGroup from "@/components/common/TableButtonGroup";
import SalesAgentAdd from "@/apps/partners/components/SalesAgentAdd";

const querySalesAgents = gql`
query salesAgents($salesOffices: [NeuraumRef], $offset: Int, $limit: Int, $order: String, $q: String) {
  salesAgents(salesOffices: $salesOffices, offset: $offset, limit: $limit, order: $order, q: $q) {
    totalCount
    data {
      nid
      mandatoryMember
      firstName
      lastName
      activePapp
      updatedAt
    }
  }
}
`
const deleteSalesAgent = gql`
  mutation deleteSalesAgent($nid: ID!){
    deleteSalesAgent(nid: $nid)
  }
`

const fields = [
  {
    key: 'edit_item',
    label: '',
    _style: 'width:1%',
    sorter: false,
    filter: false
  },
  { key: 'mandatoryMember', _style:'min-width:200px' },
  { key: 'firstName', _style:'min-width:200px' },
  { key: 'lastName', _style:'min-width:200px' },
  { key: 'activePapp', label: 'PApp', _style: 'width:1%'},
  { key: 'nid', label: 'NID', _style:'min-width:50px'},
  { key: 'updatedAt', label: 'Last Updated', _style:'min-width:50px'},
]

export default {
  name: "SalesAgents",
  props: ["salesOffice"],
  components: {
    SalesAgentAdd,
    DeleteModal,
    TableButtonGroup
  },
  data() {
    return {
      waiting: false,
      salesAgents: {},
      orderColumn: "updatedAt",
      orderPrefix: "-",
      tableFilter: null,
      itemsPerPage: 20,
      queryOffset: 0,
      activePage: 1,
      error: null,
      fields: fields,
      showDeleteItemModal: false,
      deleteItemID: null,
      deleteItemName: null
    }
  },
  methods: {
    updateOrder(event){
      if(event.column==='salesOffice'){
        this.orderColumn = 'salesOffice.name';
      } else {
        this.orderColumn = event.column;
      }
      this.orderPrefix = event.asc ? '': '-';
    },
    updateFilter(event){
      this.tableFilter = event;
    },
    updatePagination(event){
      this.itemsPerPage = event;
    },
    openAddModal(){
      this.$store.commit('set', ['allowEdit', true]);
      this.$store.commit('set', ['addAgentModalShow', true]);
    },
    getItemName(item){
      return item.name
    },
    editItem(item){
      this.$store.commit('set', ['allowEdit', true]);
      this.$router.push({ path: `/partners/sales-agent/${item.nid}`});
    },
    viewItem(item){
      this.$store.commit('set', ['allowEdit', false]);
      this.$router.push({ path: `/partners/sales-agent/${item.nid}`});
    },
    toggleDeleteItemModal(item){
      this.deleteItemID = item.nid;
      this.deleteItemName = this.getItemName(item);
      this.showDeleteItemModal = !this.showDeleteItemModal
    },
    deleteItem(item){
      this.$apollo.mutate({
        mutation: deleteSalesAgent,
        variables: {
          nid: this.deleteItemID
        },
        error(error){
          console.error(error);
          this.$emit("error", JSON.stringify(error));
          this.deleteItemID = null;
          this.deleteItemName = null;
          this.showDeleteItemModal = false;
        }
      })
      .then(res => {
        this.waiting = true;
        setTimeout(()=> {
          this.$apollo.queries.salesAgents.refetch(() => {
            return {
              offset: this.offset,
              limit: this.itemsPerPage,
              order: this.sortOrder,
              q: this.tableFilter
            }
          })}, 500);
        this.waiting = false;
        this.deleteItemID = null;
        this.deleteItemName = null;
        this.showDeleteItemModal = false;
      })
    },
    updateItemLocal(data){
      this.salesAgents.data = this.salesAgents.data.filter(function(item) {return item.nid === data.nid ? data : item });
    },
    addItemLocal(item){
      if(this.salesAgents.data.length >= this.itemsPerPage){
        this.salesAgents.data.pop();
      }
      this.salesAgents.data = [item].concat(this.salesAgents.data);
    },
    fetchData(){
      if(this.doRefetch){
        this.queryOffset = 0
        this.tableFilter = null
        this.orderPrefix = '-'
        this.orderColumn = 'updatedAt'
        this.waiting = true
        setTimeout(()=> {
          this.$apollo.queries.salesAgents.refetch(() => {
            return {
              offset: this.offset,
              limit: this.itemsPerPage,
              order: this.sortOrder,
              q: this.tableFilter
          }});
        }, 500);
        this.waiting = false;
        this.$store.commit('set', ['refetchData', false]);
      }
    }
  },
  apollo: {
    salesAgents: {
      query: querySalesAgents,
      variables(){
        return {
          salesOffices: this.salesOffices,
          offset: this.offset,
          limit: this.itemsPerPage,
          order: this.sortOrder,
          q: this.tableFilter
        }
      },
      error(error) {
        console.error(error);
        this.$emit("error", JSON.stringify(error));
      },
      skip () {
        return !this.salesOffice.nid
      },
      pollInterval: 10000,
      fetchPolicy: 'cache-and-network'
    }
  },
  computed:{
    allowEdit: {
      get() {
        return this.$store.state.allowEdit
      },
      set (){
      }
    },
    doRefetch(){
      return this.$store.state.refetchData
    },
    loading(){
      return this.$apollo.queries.salesAgents.loading || this.waiting
    },
    salesOffices(){
      return this.salesOffice? [{nid: this.salesOffice.nid}]: [];
    },
    sortOrder: {
      get() {
        return `${this.orderPrefix}${this.orderColumn}`
      },
      set(){

      }
    },
    pages:{
      get() {
        return Math.ceil(this.salesAgents.totalCount / this.itemsPerPage)
      },
      set(){

      }
    },
    offset:{
      get() {
        return this.itemsPerPage*(this.activePage-1)
      },
      set(){

      }
    },
    items () {
      if(this.salesAgents.data){
        return this.salesAgents.data.map(item => {
          return {
            ...item,
            salesOffice: item.salesOffice? item.salesOffice.name: ''
          }
        })
      } else {
        return []
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.fetchData())
  },
}
</script>

<style scoped>
  .add-button-row{
    justify-content: start;
    margin-left: 1.25rem;
  }
  .navbar-partner{
    align-items: start;
    align-content: start;
    justify-content: start;
    padding-left: 0px;
    margin-left: 0px;
  }
  .nav-item-partner{
    list-style: none;
  }
</style>